import { IBasicFetchHook, IFetchHook } from "./fetch";
import { IHotKey } from "./hotkey";
import {
  IFrontendInitOptions
} from '@staffbar/rpc';

export class Hub {
  initOptions?: IFrontendInitOptions;
  basicFetchHook?: IBasicFetchHook;
  fetchHook?: IFetchHook;
  hotKey?: IHotKey;
}

/**
 * Anything the can hold the singleton __STAFFBAR__ container
 */
interface IGlobalContainer {
  __STAFFBAR__:Hub;
}

declare global {
  // Window is the most common global container
  interface Window {
  __STAFFBAR__:Hub;
  }
}

export interface IGlobalContainerResolver {
  (): IGlobalContainer
}

const getGlobalContainerWindow: IGlobalContainerResolver = (): IGlobalContainer => {
  return window as IGlobalContainer
}

export const defaultGetGlobalContainer = getGlobalContainerWindow

export const getHub = ({ getGlobalContainer = getGlobalContainerWindow }: { getGlobalContainer?: IGlobalContainerResolver } = {}): Hub => {
  let container = getGlobalContainer()

  // If we don't have one, create it
  if (!container.__STAFFBAR__){
    container.__STAFFBAR__ = new Hub()
  }

  return container.__STAFFBAR__
}
