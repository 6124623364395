import { IRPCRequest, IRPCResponse, RPCResponse } from "./types";
import { IRPCPublishment, RPCPublishment } from "./publishment";

// TODO: Add better tests for serializaton

export const RPCRequestMessageTypeName = 'RPC_REQUEST'
export const RPCResponseMessageTypeName = 'RPC_RESPONSE'
export const RPCPublishmentMessageTypeName = 'RPC_PUBLISHMENT'

export enum RPCMessageType {
  Unknown,
  Request,
  Response,
  Publishment
}

export type ValidRPCMessageTypes = RPCMessageType.Publishment | RPCMessageType.Request  | RPCMessageType.Response

export const messageTypeToString = (messageType: RPCMessageType): string | undefined => {
  switch(messageType) {
    case RPCMessageType.Request:
      return RPCRequestMessageTypeName
    case RPCMessageType.Response:
      return RPCResponseMessageTypeName
    case RPCMessageType.Publishment:
      return RPCPublishmentMessageTypeName
  }
  return undefined;
}

export const messageTypeFromString = (messageTypeString: string | undefined): RPCMessageType => {
  switch(messageTypeString) {
    case RPCRequestMessageTypeName:
      return RPCMessageType.Request
    case RPCResponseMessageTypeName:
      return RPCMessageType.Response
    case RPCPublishmentMessageTypeName:
      return RPCMessageType.Publishment
  }
  return RPCMessageType.Unknown
}

export type RPCMessage = IRPCRequest | IRPCResponse | IRPCPublishment

export interface RPCMessageWrapper {
  messageType: string,
  rpc: RPCMessage
}

export class Serializer {

  constructor(messageType: ValidRPCMessageTypes, message: RPCMessage) {
    this._messageType = messageType
    this._message = message
  }

  private _messageType: ValidRPCMessageTypes
  private _message: RPCMessage

  public serialize(): string {
    return JSON.stringify({
      messageType: messageTypeToString(this._messageType),
      rpc: this._message
    })
  }
}

export class Deserializer {

  constructor(payload: string) {
    this._payload = payload
  }
  
  private _payload: string

  private _attemptedDeserialization: boolean = false
  private _deserializedCache: RPCMessageWrapper | undefined = undefined
 
  public messageValid(): boolean {
    let deserialized = this._deserialized()
    // If we can't parse or the body is invalid
    if (
      deserialized === undefined || 
      deserialized.rpc === undefined ||
      typeof deserialized.rpc !== "object"
    ){
        return false
      }
    if (this.messageType() === RPCMessageType.Unknown) {
      return false
    }
    return true
  }

  public messageType(): RPCMessageType {
    let deserialized = this._deserialized()
    if ( 
      deserialized !== undefined && 
      deserialized.rpc !== undefined) {
      return messageTypeFromString(deserialized.messageType)
    }
    return RPCMessageType.Unknown
  } 

  public toRequest(): IRPCRequest {
    if (
      this.messageType() !== RPCMessageType.Request ||
      !this.messageValid()
    ) 
    {
      throw new Error('Payload does not contain a valid request')
    }
    return this._deserialized()!.rpc as IRPCRequest
  }

  public toResponse(): RPCResponse {
    if (
      this.messageType() !== RPCMessageType.Response ||
      !this.messageValid()
    ) 
    {
      throw new Error('Payload does not contain a valid reponse')
    }
    return new RPCResponse(this._deserialized()!.rpc as IRPCRequest)
  }

  public toPublishment(): IRPCPublishment  {
    if (
      this.messageType() !== RPCMessageType.Publishment ||
      !this.messageValid()
    ) 
    {
      throw new Error('Payload does not contain a valid publishment')
    }
    return new RPCPublishment(this._deserialized()!.rpc as IRPCPublishment) 

  }

  private _deserialized(): RPCMessageWrapper | undefined {
    if (this._attemptedDeserialization) { 
      return this._deserializedCache
    }
    this._attemptedDeserialization = true
    try {
      this._deserializedCache = JSON.parse(this._payload)
    } catch {}
    return this._deserializedCache
  } 
}
