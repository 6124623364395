import {
  BasicFetchHook
} from './basic-fetch-hook';

import {
  getHub
} from '@staffbar/inject-core';
import { IFrontendInitOptions, validateFrontendOptions, ValidatedFrontendInitOptions } from '@staffbar/rpc';
import {
  IEnhancementHostConfig
} from '@staffbar/types';

type BootstrapLiteResult = [ValidatedFrontendInitOptions, false] | [undefined, true];

/**
 * Lightest weight bootstrap to be used in a render-blocking context as a script or as
 * a direct embedd.
 *  
 * @param apiKey StaffBar API Key - ...
 * @param options configuration for this deployment
 * 
 * @returns A promise to support asynchronous bootstrapping in the future.
 */
export const bootstrapLite = (initOptions: IFrontendInitOptions): Promise<BootstrapLiteResult> => {
  // Everything must be idempotent here, it must also be synchronus & blocking
  let [validatedOptions, warnings, fatalError] = validateFrontendOptions(initOptions)
  warnings.forEach(console.warn)
  if (fatalError) {
    console.error('StaffBar configuration is invalid, cannot continue')
    // Configuration is invalid, 

    return new Promise<BootstrapLiteResult>((resolve) => {
      resolve([undefined, true])
    })
  }

  getHub().initOptions = validatedOptions
  const { devBackendHosts } = validatedOptions;

  // If we have `devBackendHosts`, we map them into enhancement configs.
  // We will only do this if we're in dev
  var enhancementHostConfigs: IEnhancementHostConfig[] = [];
  if (devBackendHosts && validatedOptions.dev) {
    enhancementHostConfigs = devBackendHosts.map((host) => {
      // Handle http://localhost:8080/234
      try {
        let url = new URL(host);
        if (url.protocol === 'http:' || url.protocol === 'https:') {
          return url.host
        }
      } catch {
      }

      // Handle localhost:8080
      return host 
    }).map((host) => {
      return {
        id: host.replace(':', '-'),
        host
      }
    })
  }

  // TODO: Use `dev` and `authenticatedHint` to decide if we're going to bootstrap or not
  BasicFetchHook.shared()!.install({ enhancementHostConfigs })

  return new Promise((resolve) => {
    resolve([Object.assign(validatedOptions, { enhancementHostConfigs }), false]);
  })
}
