


export enum RPCPublishmentName {
  FetchRequestStarted = 'fetch.onRequestStarted',
  // FetchRequestSucceeded = 'fetch.onRequestSucceeded',
  // FetchRequestFailed = 'fetch.onRequestFailed',
  FetchMetadataUpdated = 'fetch.onRequestMetadataUpdated',
  InjectHotKeyPressed = 'inject.HotKeyPressed',
}



/**
 * Pub-sub publish
 */
 export interface IRPCPublishment {
  publishmentName: string
  message: any
}



export class RPCPublishment implements IRPCPublishment {

  constructor({ publishmentName, message }: { publishmentName: string, message: string }) {
    this.publishmentName = publishmentName;
    this.message = message;
  }

  public publishmentName: string;
  public message: any;



}
