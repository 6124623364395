import {
  IRequestMetadataNative,
  ISimpleFetchPublisher,
} from '@staffbar/rpc-iframe';

import {
  nanoid,
  getHub,
  defaultGetGlobalContainer,
  IGlobalContainerResolver,
  IBasicFetchHook,
  INativeFetch,
  EnhancementController,
} from '@staffbar/inject-core';
import { IEnhancementHostConfig } from '@staffbar/types';

type Replacement = [object, string, any]
type ReplacementMap = {[key: string]: Replacement[]} 

const replace = (obj: any, name: string, replacement: any, replacements?: ReplacementMap, type?: string) => {
  var orig = obj[name];
  obj[name] = replacement(orig);
  if (replacements && type) {
    replacements[type].push([obj, name, orig]);
  }
}


export class BasicFetchHook {

  // Only allow access through the singleton
  private constructor() {
    this.enhancementController = new EnhancementController();
  }
  public enhancementController: EnhancementController;


  public requests: {[key: string]: IRequestMetadataNative} = {}
  public publisher?: ISimpleFetchPublisher;

  public static shared({ getGlobalContainer = defaultGetGlobalContainer }: { getGlobalContainer?: IGlobalContainerResolver } = {}): IBasicFetchHook {
    let hub = getHub({ getGlobalContainer }) 
    if (!hub.basicFetchHook) {
      hub.basicFetchHook = new BasicFetchHook()
    }
    return hub.basicFetchHook!
  }

  public getRealFetch(): INativeFetch {
    return this._fetch!
  }

  private _installed: boolean = false


  public install({ 
    enhancementHostConfigs,
    // allLocalhost,
  }: { 
    allLocalhost?: boolean,
    enhancementHostConfigs?: IEnhancementHostConfig[] 
  } = {}) {
    if (this._installed) {
      return;
    }

    // Already installed
    if (enhancementHostConfigs) {
      this.enhancementController.addHostConfigs(enhancementHostConfigs)
    }

    // if (allLocalhost) {
    //   this.enhancementController.setConfigValue({ allLocalhost })
    // }

    this.installFetch()
    this.installXHR()
  }

  private _replacements: ReplacementMap = {
    'network': []
  };

  private _xhrInstalled: boolean = false;

  public installXHR() {
    if (this._xhrInstalled) {
      return 
    }
    this._xhrInstalled = true;
    var basicFetchHook = this;
    if ('XMLHttpRequest' in window) {
      let  xhrp = window.XMLHttpRequest.prototype;
      replace(xhrp, 'open', function(orig: any) { 
        return function(this: any, method: string, url: string) {
          if (typeof url === 'string') {
            if (this.__staffbar_xhr) {
              this.__staffbar_xhr.id = nanoid();
              this.__staffbar_xhr.method = method;
              this.__staffbar_xhr.url = url;
              this.__staffbar_xhr.status_code = null;
              this.__staffbar_xhr.start_time_ms = Date.now();
              this.__staffbar_xhr.end_time_ms = null;
              this.__staffbar_xhr.enhancement = null;
            } else {
              this.__staffbar_xhr = {
                id: nanoid(),
                method: method,
                url: url,
                status_code: null,
                start_time_ms: Date.now(),
                end_time_ms: null,
                enhancement: null,
              };
            }
          }

        return orig.apply(this, arguments);
        }
      }, this._replacements, 'network')
    
      replace(xhrp, 'setRequestHeader', function(orig: any) {
        return function(this: any, header: string, value: string) {
          // If xhr.open is async, __staffbar_xhr may not be initialized yet.
          if (!this.__staffbar_xhr) {
            this.__staffbar_xhr = {};
          }
          if (typeof header ===  'string' && typeof value === 'string') {
            if (!this.__staffbar_xhr.request_headers) {
              this.__staffbar_xhr.request_headers = {};
            }
            this.__staffbar_xhr.request_headers[header] = value;
            // We want the content type even if request header telemetry is off.
          }
          return orig.apply(this, arguments);
        };
      }, this._replacements, 'network');

      replace(xhrp, 'send', function(orig: any) {
        /* eslint-disable no-unused-vars */
        return function(this: any, data: any) {
        /* eslint-enable no-unused-vars */
          var xhr = this;
       
          let { enhancement } = basicFetchHook.enhancementController.beforeXHRRequest({
            xhr
          })
          xhr.__staffbar_xhr.enhancement = enhancement;

          function onreadystatechangeHandler() {
            if (xhr.__staffbar_xhr) {

              // Sometimes onreadystatechange is only called once so we have to be able to "start"
              // the request and "finish" it in the same cycle.
              if (xhr.__staffbar_xhr.status_code === null) {
                xhr.__staffbar_xhr.status_code = 0;

                // Request is starting
                let { 
                  id, 
                  url,
                  method,
                  request_headers, 
                  start_time_ms,
                  enhancement,
                } = xhr.__staffbar_xhr;
                // Convert it into a normal fetch request
                
                let body = (method === 'GET' || method === 'HEAD') && data ? undefined : data
                let request = new Request(url, {
                  method, 
                  body,
                  headers: request_headers,
                })
                // before request here? 
                let requestStarted = {
                  id,
                  request,
                  requestBody: data,
                  enhancement,
                  // We're ignoring enhancements for now
                  // enhancement: enhancement.enhancement,
                  initiatedTimestamp: start_time_ms || Date.now(), 
                }
                // debugger;
                basicFetchHook.requests[id] = requestStarted
        
                // Publish request started
                basicFetchHook.publisher?.onRequestStarted(requestStarted)
              }

              if (xhr.readyState < 2) {
              }

              // Request finished
              if (xhr.readyState > 3) {
                xhr.__staffbar_xhr.end_time_ms = Date.now();

                // Parse headers & body
                let headers : {[key: string]: any} = {};
                try {
                  var header, i;
                  var allHeaders = xhr.getAllResponseHeaders();
                  if (allHeaders) {
                    var arr = allHeaders.trim().split(/[\r\n]+/);
                    var parts, value;
                    for (i=0; i < arr.length; i++) {
                      parts = arr[i].split(': ');
                      header = parts.shift();
                      value = parts.join(': ');
                      headers[header] = value;
                    }
                  } 
                } catch (e) {
                  /* we ignore the errors here that could come from different
                    * browser issues with the xhr methods */
                }
                let body: string = '';
                try {
                  body = xhr.responseText;
                } catch (e) {
                  /* ignore errors from reading responseText */
                }

                // We got a response... 
                let { id } = xhr.__staffbar_xhr;
                try {
                  var code = xhr.status;
                  code = code === 1223 ? 204 : code;

                  // Code 0 is like the http error 
                  if (code === 0) {
                    let error = new TypeError('Failed to fetch')
                    let update = { error }
                    Object.assign(basicFetchHook.requests[id], update)
                    basicFetchHook.publisher?.onRequestUpdated(Object.assign({ id }, update), 'failed')
                  } else {
                    // some response
                    // debugger;
                    let update = {
                      response: new Response(body, {
                        status: code,
                        headers
                      }),
                      responseText: body,
                      completedTimestamp: xhr.__staffbar_xhr.end_time_ms || Date.now()
                    }
                    Object.assign(basicFetchHook.requests[id], update)
                    // Publish update
                    basicFetchHook.publisher?.onRequestUpdated(Object.assign({ id }, update), 'succeeded')
                  }
                } catch (e) {
                  /* ignore possible exception from xhr.status */
                  console.error(e)
                }
              }
            }
          }

          if ('onreadystatechange' in xhr && typeof xhr.onreadystatechange === 'function') {
            replace(xhr, 'onreadystatechange', function(orig: any) {
              return function(this: any) {
                // Call both our handler & your handler
                onreadystatechangeHandler.apply(this, arguments);
                return orig.apply(this, arguments);
              }
            })
          } else {
            xhr.onreadystatechange = onreadystatechangeHandler;
          }
          return orig.apply(this, arguments);
        }
      }, this._replacements, 'network');
    }
  }

  private _fetch?: INativeFetch = undefined;
  public installFetch() {
    if (this._fetch !== undefined &&  'fetch' in window) { return; }
    this._fetch = window.fetch
    window.fetch = (input: RequestInfo, init?: RequestInit): Promise<Response> =>  {
      const basicFetchHook = BasicFetchHook.shared()

      var request = new Request(input, init)
      const id: string = (input as any)._staffBarRequestId || nanoid()
      const enhancementHostConfig: IEnhancementHostConfig = (input as any)._staffBarEnhancementHostConfig;
      // Allow our enhancements to apply extra headers
      let enhancement = basicFetchHook.enhancementController.beforeRequest({
        id, 
        request,
        enhancementHostConfig,
      })
      request = enhancement.request

      let clonedRequest = request.clone()

      return clonedRequest.text().then((text) => {

        let requestStarted = {
          id,
          request: clonedRequest,
          requestBody: text,
          enhancement: enhancement.enhancement,
          initiatedTimestamp: Date.now(),
        }
        this.requests[id] = requestStarted

        // Publish request started
        this.publisher?.onRequestStarted(requestStarted)
        return basicFetchHook.getRealFetch()(request).then((resp: Response) => {
          let clonedResponse = resp.clone()
          clonedResponse.text().then((text) => {

            let update = {
              response: clonedResponse,
              responseText: text,
              completedTimestamp: Date.now(),
            }
            Object.assign(this.requests[id], update)
            // Publish update
            this.publisher?.onRequestUpdated(Object.assign({ id }, update), 'succeeded')
          })
          return resp
        }).catch((error: Error) => {
          let update = { error }
          Object.assign(this.requests[id], update)
          this.publisher?.onRequestUpdated(Object.assign({ id }, update), 'failed')
          throw error
        })
      })
    }
  }
}
