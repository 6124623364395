import {
  IEnhancementHostConfig
} from '@staffbar/types';

export type Host = string;

export type ValidatedFrontendInitOptions = IFrontendInitOptions & IRequiredFrontendInitOptions;

// options, warnings, fatal error
export const validateFrontendOptions = (initOptions: IFrontendInitOptions): [ValidatedFrontendInitOptions, string[], boolean] => {
  // Handle the overriding of dev 
  var fatalError: boolean = false;
  var warnings: string[] = [];

  let { 
    dev,
    env,
    apiKey, 
    staffBarHost,
  } = initOptions

  let envOriginallyUndefined = env === undefined
  // We don't have an env
  env = env || 'dev'

  // If dev is true env must be 'dev'
  if (dev === true) {
    env = 'dev'
  } else if (dev === false) {
    // env shouldn't be dev, but we don't know what it should be
    if (env === 'dev') {
      if (envOriginallyUndefined) {

      } else {
        warnings.push('You have set dev: false but env is set to dev. This is an unsupported combination. Please set dev: true or change env.')
      }
    }
    // Else we're good
  } else if (dev === undefined) { 
    // If def is not set, figure it out from env
    if (env === 'dev') {
      dev = true
    } else {
      dev = false
    }
  }
  apiKey = apiKey ||  '-';
  staffBarHost = staffBarHost || 'https://app.staffbar.com'

  try { 
    new URL(staffBarHost)
  } catch(e) {
    // Invalid staffbar host
    warnings.push('Fatal: Unable to parse provided staffbar. Please check your staffBarHost value.')
    fatalError = true;
  }

  return [Object.assign({}, initOptions, {
    dev,
    env,
    apiKey,
    staffBarHost
  }), warnings, fatalError]
}

export interface IRequiredFrontendInitOptions {
  dev: boolean,
  apiKey: string,
  env: string,
  staffBarHost: string,
}

export interface IFrontendInitOptions {
  // If true, this will set `env` to dev
  dev?: boolean

  // API Key -> used to identify the project
  apiKey?: string

  // What hosts should we apply enhancements to in dev 
  // useful if we 
  devBackendHosts?: Host[]

  // What environment are we running in? 
  // `dev` is special
  env?: string

  // Useful to display what version of the front end you are running
  frontendCommitHash?: string

  // What staffbar host are you using, this will default to 
  // app.staffbar.com
  staffBarHost?: string
}

// Options that allow us to override how boostrapping happens in dev
export interface IUndocumentedFrontendInitOptions {
  // Overrides the source from which we load the iframe.
  clientSrc?: string

  // Delays the iframe until we've triggered some action, used in the chrome extension
  delayIframeSetup?: boolean

  enhancementHostConfigs?: IEnhancementHostConfig[]

  // Starting display format, used to open staffbar in full screen mode.
  startingDisplayFormat?: string

  // Hosts to filter out of all stores
  ignoredHosts?: []
}

export interface IRequiredBackendInitOptions {
  dev: boolean,
  apiKey: string,
  env: string,
  staffBarHost: string,
}

export type ValidatedBackendInitOptions = IBackendInitOptions & IRequiredBackendInitOptions;

export interface IBackendInitOptions {
  // If set to true, we will _not_ validate any tokens
  dev?: boolean

  // What environment are we running in? 
  // `dev` is special
  env?: string

  // API key to autenticate against the api
  apiKey?: string

  // Useful to say what version of the backend we're running
  backendCommitHash?: string

  // What, if any enhancements you explictly want to disable 
  disabledEnhancements?: []

  // What staffbar host are you using, this will default to 
  // app.staffbar.com
  staffBarHost?: string

  projectDirectory?: string,
}

const allowedNodeEnvValuesForDev = [
  undefined,
  'dev',
  'development',
]

// options, warnings, fatal error
export const validateBackendOptions = (initOptions: IBackendInitOptions): [ValidatedBackendInitOptions, string[], boolean] => {
  // Handle the overriding of dev 
  var fatalError: boolean = false;
  var warnings: string[] = [];

  let { 
    dev,
    env,
    apiKey, 
    staffBarHost,
  } = initOptions

  let envOriginallyUndefined = env === undefined
  // We don't have an env
  env = env || 'dev'

  // If dev is true env must be 'dev'
  if (dev === true) {
    env = 'dev'
  } else if (dev === false) {
    // env shouldn't be dev, but we don't know what it should be
    if (env === 'dev') {
      if (envOriginallyUndefined) {

      } else {
        warnings.push('You have set dev: false but env is set to dev. This is an unsupported combination. Please set dev: true or change env.')
      }
    }
    // Else we're good
  } else if (dev === undefined) { 
    // If def is not set, figure it out from env
    if (env === 'dev') {
      dev = true
    } else {
      dev = false
    }
  }
  apiKey = apiKey ||  '-';
  staffBarHost = staffBarHost || 'https://app.staffbar.com'

  try { 
    new URL(staffBarHost)
  } catch(e) {
    // Invalid staffbar host
    warnings.push('Fatal: Unable to parse provided staffbar. Please check your staffBarHost value.')
    fatalError = true;
  }

  if (dev && !allowedNodeEnvValuesForDev.includes(process.env.NODE_ENV)) {
    warnings.push('Fatal: Refusing to start in dev mode because your NODE_ENV value is not `undefined`, `dev` or `development`.')
    fatalError = true;
  }

  return [Object.assign({}, initOptions, {
    dev,
    env,
    apiKey,
    staffBarHost
  }), warnings, fatalError]
}
