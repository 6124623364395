import {
  IEnhancementRecord,
  IEnhancementConfig,
  IEnhancementHostConfig,
} from '@staffbar/types';


const defaultEnhancemntConfig: IEnhancementConfig = {
  hosts: []
}

const withLocalDev = (config: IEnhancementConfig): IEnhancementConfig => {
  return config;

  // Temporarily disables this 
  let { hosts } = config;

  let defaultLocalhost;
  if (typeof window !== 'undefined' && window.location.host) {
    // let { host, hostname } = window.location;
    let { host, hostname } = window.location;
    if (hostname === 'localhost') {
      defaultLocalhost = {
        id: `default-localhost`,
        token: {
          exp: Date.now() + 3600 * 24 * 365,
          value: '',
        },
        matcher:  host,
        enhancements: ['console']
      }
    }
  }

  let updatedHosts = defaultLocalhost ?  hosts.concat([defaultLocalhost]) : hosts
  return {
    hosts: updatedHosts
  }
}

const load = (): IEnhancementConfig => {
  let value = localStorage.getItem('__STAFFBAR__ENHANCEMENTS__')
  if (value === null) {
    return defaultEnhancemntConfig;
  }
  try {
    return JSON.parse(value);
  } catch {
    return defaultEnhancemntConfig;
  }
}

export class EnhancementController {

  constructor() {
    this._config = withLocalDev(load())
  }

  private _config: IEnhancementConfig;

  public get config(): IEnhancementConfig {
    return this._config
  }

  public addHostConfigs(hostConfigs: IEnhancementHostConfig[]) {
    const { hosts, ...rest } = this._config;
    this._config = { ...rest, hosts: hosts.concat(hostConfigs) }
  }

  public setConfigValue(arg: {[key: string]: any}) {
    Object.assign(this._config, arg)
  }

  public beforeXHRRequest({
    xhr
  }:{
    xhr: XMLHttpRequest,
  }) {

    let {
      id,
      url,
    } = ((xhr as any).__staffbar_xhr || {});

    let enhancementConfig =  this._findEnhancementHostConfig({ url })
    if (enhancementConfig) {
      let { id: hostId, enhancements, token } = enhancementConfig;
      enhancements = enhancements || ['*'];

      var additionalHeaders: { [key: string]: string} = {
        'X-StaffBar-Request-Id': id,
        'X-StaffBar-Enhancements': enhancements.join(','),
      }

      if (token) {
        additionalHeaders['X-StaffBar-Token'] = token.value
      }

      Object.entries(additionalHeaders).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      })

      return {
        xhr, 
        enhancement: { 
          hostId, 
          enhancements,
          enhancementHostConfig: enhancementConfig,
        }
      }
    }
    return {
      xhr
    }
  }

  // Like a pre-request middleware
  public beforeRequest({
    id, 
    request, 
    enhancementHostConfig,
  }:{
    id: string, 
    request: Request
    enhancementHostConfig?: IEnhancementHostConfig,
  }): {  
    request: Request, 
    enhancement?: IEnhancementRecord,
  } {

    let { url } = request;
    let enhancementConfig = this._findEnhancementHostConfig({ url, enhancementHostConfig })

    if (enhancementConfig) {
      let { id: hostId, enhancements, token } = enhancementConfig;
      enhancements = enhancements || ['*'];

      var additionalHeaders: { [key: string]: string} = {
        'X-StaffBar-Request-Id': id,
        'X-StaffBar-Enhancements': enhancements.join(','),
      }

      if (token) {
        additionalHeaders['X-StaffBar-Token'] = token.value
      }

      Object.entries(additionalHeaders).forEach(([key, value]) => {
        request.headers.set(key, value);
      })

      return { 
        request, 
        enhancement: { 
          hostId, 
          enhancements,
          enhancementHostConfig: enhancementConfig,
        }
      };
    }

    // By default don't touch it
    return { request } 
  }

  private _findEnhancementHostConfig({
    url: urlString,
    enhancementHostConfig
  }: {
    url: string,
    enhancementHostConfig?: IEnhancementHostConfig,
  }): IEnhancementHostConfig | undefined {
    let enhancementConfig = enhancementHostConfig;
    if (!enhancementConfig) {
      // Check every host config to see if we're going to modify te
      for (let i = 0; i < this._config.hosts.length; i++) {
        let config = this._config.hosts[i];
        let { host, token } = config;

        let url:URL;
        try{
          url = new URL(urlString)
        } catch {
          continue;
        }
        /**
         * Part of the threat model hinges on if the request sent by fetch will
         * actually end up going to the same host as returned by `new
         * URL(request.url).host`.
         *
         * I _think_ it should but it's possible that URL parsing is horrible (as
         * it always is) and someone could break this assumption. 
         *
         * I think we could prevent this problem by requiring a cookie to be
         * present on all requests which use the enhancement protocol. The browser
         * would make a request to `exampel.com/_staffbar/cookie`. which would
         * store a cookie and return an updated token to use on future requests.
         * The server could then validate that both the token was valid and that
         * the token was signed by a secret stored in an HttpOnly cookie. That way
         * the token would only be useful in that browser session. To exfiltrate
         * the token you woudld have to redirect a request which we have complete
         * control over. 
         * 
         * 
         */

        if (url.host === host && 
            // If token, make sure it's valid, otherwise return true
          (token ? token.exp > new Date().getTime() : true) 
          ) {
          enhancementConfig = config;
        }
      }
    }
    return enhancementConfig;
  }
}
