import {
  IInit
} from '@staffbar/inject-core';
import { IFrontendInitOptions } from '@staffbar/rpc';

import {
  bootstrapLite,
} from './bootstrap-lite';

/**
 * How the bootstrapping of the injectable works
 * 
 * For delivery via Chrome Extension or "Analytics Tag"-like integreations,
 * we'll always have a script tag which is injected into the head of the
 * page whose source is `entrypoint{.min|}.js` or whose `data-src=""` attribute will point
 * to the location where the entrypoint script was loaded from. 
 * 
 * Once we've figured out where the `entrypoint.js` file came from, we'll now load the
 * complete.js file from the same directory.
 * 
 * The staffbar client url can also be inferred from this first script tag or it can be 
 * passed into the original call to the init script. If passed implictly (via the script tag)
 * 
 */

interface IInjectedScriptParameters {
  entrypointSrc: string,
  completeSrc: string,
  clientSrc?: string,
}

const findInjectedScriptParameters = (): IInjectedScriptParameters => {
  
    // Find the script tage with the `staffbar-inject-entrypoint`
    let injectedScriptTag = ((document.getElementById('staffbar-inject-entrypoint') || {}) as any)

    if (!injectedScriptTag) {
      throw new Error('Unable to find script tag that loaded staffbar, please ensure the script tag has an id of: \'staffbar-inject-entrypoint\'')
    }

    let entrypointSrc = injectedScriptTag['src'] || injectedScriptTag.getAttribute('data-src');

    // Resolve the source of the `complete.js` file from the injected src 
    let originalSourceParts: string[] = entrypointSrc.split('/')
    let prefix = originalSourceParts.slice(0, -1).join('/')
    let completeSrc = prefix  + '/' + originalSourceParts[originalSourceParts.length - 1].replace('entrypoint', 'complete')

    // Attempt to load the client src
    let clientSrc = injectedScriptTag.getAttribute('data-client-src')

    return {
      entrypointSrc: entrypointSrc,
      completeSrc,
      clientSrc,
    }
}

// Loads in the secondary javascript in a non render-blocking way
const loadCompleteScript = (resolvedOptions: IInjectedScriptParameters) => {
  let script = document.createElement('script')
  script.async = true
  script.src = resolvedOptions.completeSrc
  document.head.appendChild(script)
}

const _init = (initOptions: IFrontendInitOptions= {}) => {
  // Bootstrap everything we can now
  let injectedScriptParams = findInjectedScriptParameters()
  let resolvedOptions = Object.assign({}, injectedScriptParams, initOptions)
  bootstrapLite(resolvedOptions).then(() => {
    if (document.readyState === "complete" || document.readyState === "interactive") {
      loadCompleteScript(resolvedOptions)
      loadCompleteScript(resolvedOptions)
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        loadCompleteScript(resolvedOptions)
      })
    }
  })
}

const init: IInit = _init;
/**
 * Allow bootstrap full to call this as well
 */
export { init, bootstrapLite };

